export const Values = [
  {
    year: "2018-2022 (Expected)",
    school: "University of California, Davis",
    degree: "Doctor of Philosophy (PhD)",
    subject: "Electrical Engineering (EE)",
  },
  {
    year: "2014-2016",
    school: "Utah State University",
    degree: "Master of Science (MS)",
    subject: "Electrical Engineering (EE)",
  },
  {
    year: "2012-2014",
    school: "Bangladesh University of Engineering and Technology (BUET)",
    degree: "Master of Science (MSc)",
    subject: "Electrical and Electronic Engineering (EEE)",
  },
  {
    year: "2007-2012",
    school: "Bangladesh University of Engineering and Technology (BUET)",
    degree: "Bachelor of Science (BSc)",
    subject: "Electrical and Electronic Engineering (EEE)",
  },
  // {
  //   year: "2004-2006",
  //   school: "Notre Dame College, Dhaka, Bangladesh",
  //   degree: "Higher Secondary School Certificate (HSC)",
  //   subject: "Science",
  // },
  // {
  //   year: "1999-2004",
  //   school: "Kushtia Zilla School, Kushtia, Bangladesh",
  //   degree: "Secondary School Certificate (SSC)",
  //   subject: "Science",
  // },
];

export const HonsAwardsValues = [
  {
    year: "2022",
    title: "UC Davis Advancement-to-Candidacy (AC) fellowship",
    school: "University of California, Davis, USA",
    others:
      "Awarded by the ECE department to students who advance to candidacy within three years of starting their PhD degree to promote timely achievement of degree objectives.",
  },
  {
    year: "2018",
    title: "Graduate Fellowship at University of California, Davis",
    school: "University of California, Davis, USA",
    others: "",
  },
  {
    year: "2014",
    title: "Graduate Assistantship at Utah State University",
    school: "Utah State University, USA",
    others: "",
  },
  {
    year: "2012",
    title: "University Merit Scholarship",
    school: "BUET",
    others: "",
  },
  {
    year: "2007",
    title: "University Admission Test Excellency Scholarship",
    school: "BUET",
    others: "Stood 14th among 6000 plus top students in whole Bangladesh",
  },
  {
    year: "2007",
    title: "Dhaka Education Board Scholarship",
    school: "",
    others: "",
  },
  {
    year: "2005",
    title: "Jessore Education Board Scholarship",
    school: "",
    others: "",
  },
  {
    year: "2002",
    title: "Junior School Scholarship",
    school: "",
    others: "",
  },
  {
    year: "1999",
    title: "Primary Education Scholarship",
    school: "",
    others: "",
  },
];

export const ProfExp = [
  {
    year: "Sep 2018-Present",
    title: "Graduate Student Researcher & Teaching Assistant",
    organization: "University of California, Davis",
    place: "Davis, California",
    others: `• Research Works
  -Design, modeling, simulation, and implementation of :
• novel ultra-wide band and high gain antennas for ground penetrating radar imaging system
• ultra-high radiation efficient antennas for millimeter-wave radar imaging system
  -Millimeter-wave antenna measurement system set up and experimentation
• Courses Instructed (As Teaching Assistant)
  - Control System I (EEC157A)
  - Electromagnetics I (EEC130B)
  - Electromagnetic Radiation and Antennas (EEC133)`,
  },

  {
    year: "Jan 2018-Aug 2018",
    title: "Lecturer",
    organization: "East West University",
    place: "Dhaka, Bangladesh",
    others: `• Courses Instructed: Electrical Circuits & Networks, Telecommunication Switching 
& Networks, Wireless Networks, and Object Oriented Programming`,
  },
  {
    year: "Mar 2017-Dec 2017",
    title: "Antenna/RF Design Engineer",
    organization: "i5 Technologies",
    place: "Logan, Utah",
    others: `• Design, optimization and fabrication of 4 × 4 and 8 × 8 multifunctional 
reconfigurable phased array systems for 5G wireless access technologies`,
  },
  {
    year: "May2015-Dec 2016",
    title: "Graduate Research Assistant",
    organization: "Utah State University",
    place: "Greater Salt Lake City Area",
    others: `• Developed a 3-D novel multi-functional pattern reconfigurable dipole antenna system
• Designed and optimized dual polarized patch antenna array at 28 GHz
• First hand experience in Vector Network Analyzer (VNA) and anechoic chamber
• Investigated the system level performance of the designed antennas`,
  },
  {
    year: "Sep 2014-Apr 2015",
    title: "Graduate Teaching Assistant",
    organization: "Utah State University",
    place: "Greater Salt Lake City Area",
    others: `• Assisted course teachers in grading undergraduate level DC Circuit course
• Taught undergraduate level AC Circuit course
• Taught and graded graduate level Electromagnetics-ll course`,
  },
  {
    year: "Oct 2012-Jul 2014",
    title: "Lecturer",
    organization: "Bangladesh University of Business & Technology (BUBT)",
    place: "Dhaka, Bangladesh",
    others: `•Theory classes conducted: Digital Logic Design, AC Circuits, Electromagnetics,
Telecommunication Theory, Digital Signal Processing-I
•Lab classes conducted: Digital Logic Design, Telecommunication Theory, Digital Signal Processing-I, Microwaves
•Supervised Thesis Title: Observation of Radiation and Bandwidth Performance of Different Types of Dipole Antennas`,
  },
];

export const publicationsValues = [
  {
    year: "2017",
    title: "Parasitic layer-based radiation pattern reconfigurable antenna for 5G communications",
    authors: "MA Hossain, I Bahceci, BA Cetiner",
    journal: "IEEE Transactions on Antennas and Propagation 65 (12), 6444-6452",
    link: "https://ieeexplore.ieee.org/abstract/document/8053786",
  },
  {
    year: "2013",
    title:
      "Reduced and conventional size multi-band circular patch antennas loaded with metamaterials",
    authors: "S Ferdous, A Hossain, SMH Chowdhury, MRC Mahdy, M Abdul",
    journal: "IET Microwaves, Antennas & Propagation 7 (9), 768-776",
    link: "https://digital-library.theiet.org/content/journals/10.1049/iet-map.2012.0582",
  },
  {
    year: "2012",
    title:
      "Conceptual and practical realization of reduced size multi-band circular microstrip patch antenna loaded with MNG metamaterial",
    authors: "SMH Chowdhury, MA Hossain, MS Ferdous, MRC Mahdy, MA Matin",
    journal: "Electrical & Computer Engineering (ICECE), 2012 7th International Conference …",
    link: "https://ieeexplore.ieee.org/abstract/document/6471680/",
  },
  {
    year: "2014",
    title:
      "Novel dual band microstrip circular patch antennas loaded with ENG and MNG metamaterials",
    authors: "M Hossain, M Ferdous, SMH Chowdhury, M Abdul Matin",
    journal: "International Journal of Antennas and Propagation 2014",
    link: "https://www.hindawi.com/journals/ijap/2014/904374/abs/",
  },
  {
    year: "2018",
    title:
      "Performance Evaluation of Rectangular Microstrip Patch Antennas Loaded with Plastic and Barium-Titanate Substrates at GSM 1800 MHz Band",
    authors: "MA Hossain, MS Alam",
    journal: "Open Journal of Antennas and Propagation 6 (03), 36",
    link: "https://www.scirp.org/html/2-1290130_87363.htm?pagespeed=noscript",
  },
  {
    year: "2014",
    title:
      "Achieving tetra band performance in simple dielectric and in metamaterial filled patch antennas",
    authors: "AAN Ovi, MRC Mahdy, MRA Zuboraj, A Al Sayem, MA Hossain, MA Matin",
    journal: "8th International Conference on Electrical and Computer Engineering, 172-175",
    link: "https://ieeexplore.ieee.org/abstract/document/7026847/",
  },
  {
    year: "2014",
    title:
      "Highly directive epsilon negative metamaterial-loaded circular patch antenna for triple-band performance",
    authors: "MH Ababil, MF Saimoom, SMH Chowdhury, MRC Mahdy, MA Matin",
    journal: "Ultra-Wideband, Short-Pulse Electromagnetics 10, 219-228",
    link: "https://link.springer.com/chapter/10.1007/978-1-4614-9500-0_20",
  },
  {
    year: "2017",
    title: "Beam Steerable Reconfigurable Antenna with Smart RF Switching on 3d Parasitic Level",
    authors: "MA Hossain",
    journal: "Utah State University",
    link: "https://www.researchgate.net/profile/M_Ababil_Hossain/publication/320163125_Beam_Steerable_Reconfigurable_Antenna_with_Smart_RF_Switching_on_3D_Parasitic_Level/links/59d1a08a4585150177f3d5d0/Beam-Steerable-Reconfigurable-Antenna-with-Smart-RF-Switching-on-3D-Parasitic-Level.pdf",
  },
  {
    year: "2015",
    title:
      "Design and analysis of rectangular microstrip patch antennas loaded with plastic and barium titanate substrates",
    authors: "M Ababil Hossain",
    journal: "Department of Electrical and Electronic Engineering (EEE)",
    link: "http://lib.buet.ac.bd:8080/xmlui/handle/123456789/3982",
  },
  {
    year: "",
    title: "EUROEM 2012 Book of Abstracts",
    authors: "E Electromagnetic",
    journal: "",
    link: "https://www.academia.edu/download/32383859/EUROEM2012-final-10July2012NPA_-SUMMA.pdf",
  },
  {
    year: "",
    title:
      "Highly Directive Multi-Band Circular Patch Antenna Partially filled with ENG-metamaterial",
    authors: "MS Ferdous, MA Hossain, SMH Chowdhury, MRC Mahdy, MA Matin",
    journal: "EUROEM 2012 Book of Abstracts, 230",
    link: "https://www.academia.edu/download/32383859/EUROEM2012-final-10July2012NPA_-SUMMA.pdf#page=231",
  },
  {
    year: "2021",
    title: "A Compact Low-Cost and Lightweight 3-D Printed Horn Antenna for UWB Systems",
    authors: "M. A. Hossain, S. Wagner, and A. Pham",
    journal:
      "IEEE International Symposium on Antennas and Propagation & USNC/URSI National Radio Science Meeting, Singapore (Accepted)",
    link: "https://ieeexplore.ieee.org/document/9704737",
  },
  {
    year: "2021",
    title:
      "An Electrically Smaller Ultra-Wideband Monopole Antenna for Ground Penetrating Radar Application",
    authors: "M. A. Hossain, S. Wagner, and A. Pham",
    journal:
      "IEEE International Symposium on Antennas and Propagation & USNC/URSI National Radio Science Meeting, Singapore (Accepted)",
    link: "https://ieeexplore.ieee.org/document/9704634",
  },
  {
    year: "2021",
    title:
      "Experimental Detection of Buried Sub-mm Diameter Wires Using Microwave Ground-Penetrating Radar",
    authors: "S. Wagner, S. Pancrazio, A. Hossain, and A. Pham",
    journal:
      "IEEE International Symposium on Antennas and Propagation & USNC/URSI National Radio Science Meeting, Singapore (Accepted)",
    link: "https://ieeexplore.ieee.org/document/9703496",
  },
  {
    year: "2021",
    title: "Digital Pre-Distortion to Correct UWB Pulses in a Boresight Test",
    authors: "S. Pancrazio, P. Nguyen, S. Wagner, A. Hossain, and A. Pham",
    journal:
      "IEEE International Symposium on Antennas and Propagation & USNC/URSI National Radio Science Meeting, Singapore (Accepted)",
    link: "https://ieeexplore.ieee.org/document/9704281",
  },
];
