import React, { useState, useEffect } from "react";
import styles from "./Styles/sections.module.scss";
import { PublicationsData } from "../utils/constants";
import { getData } from "../utils/fetchingData";
import { PapersSection } from "./PapersSection/layout";

export default function Publications() {
  const [data, setData] = useState([]);
  const path = PublicationsData;

  useEffect(() => {
    getData(path, setData);
  }, []);

  const acceptedPublicationsType = ["journal", "conference", "book"];

  const filterPapers = (paper) => {
    return acceptedPublicationsType.includes(paper.type);
  };

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1>Publications (Selected)</h1>
      </section>

      <PapersSection
        type="Journal Papers"
        data={data
          .sort((a, b) => {
            return a.position - b.position;
          })
          ?.filter((publication) => filterPapers(publication) && publication.type === "journal")}
      />
      <PapersSection
        type="Conference Papers"
        data={data?.filter(
          (publication) => filterPapers(publication) && publication.type === "conference"
        )}
      />
      <PapersSection
        type="Book Chapters"
        data={data?.filter(
          (publication) => filterPapers(publication) && publication.type === "book"
        )}
      />
    </div>
  );
}
