import React,{useEffect,useState} from "react";
import styles from "./Styles/sections.module.scss";
import { HonsAwardsValues } from "../Data/testData";
import { getData } from "../utils/fetchingData";
import {  HonsAwardsData } from "../utils/constants";
export default function HonsAwards() {
  const [data, setData] = useState([]);
  const path = HonsAwardsData;
  useEffect(() => {
    getData(path, setData);
  }, []);
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1>Honors & Awards</h1>
      </section>

      {data?.map((item, index) => {
        return (
          <div className={styles.row} key={index}>
            <div className={styles.column1}>
              <div className={styles.info}>
                <p>
                  <b>{item?.year}</b>
                </p>
              </div>
            </div>
            <div className={styles.column2}>
              <div className={styles.info}>
                <p>
                  <b>{item?.title}</b>
                  <br />
                  {item?.school}
                  <div style={{marginTop:5}}>
                  {item?.others}
                  </div>
                  
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
