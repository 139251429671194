/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Styles/sections.module.scss";
import { ProfessionalExpData } from "../utils/constants";
import { getData } from "../utils/fetchingData";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ],
  // toolbar:false
};
export default function ProfessionalExperience() {
  const [data, setData] = useState([]);
  const path = ProfessionalExpData;
  useEffect(() => {
    getData(path, setData);
  }, []);

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1>Professional Experience</h1>
      </section>

      {data
        .sort((a, b) => a.order - b.order)
        .map((item, index) => {
          return (
            <div className={styles.row} key={index}>
              <div className={styles.column1}>
                <div className={styles.info}>
                  <p>
                    <b>{item.year}</b>
                  </p>
                </div>
              </div>
              <div className={styles.column2}>
                <div className={styles.info}>
                  <p>
                    <b>{item.title}</b>
                    <br />
                    {item.organization} <br />
                    {item.place}
                    <div className="detailsWrapper">
                      <ReactQuill
                        modules={modules}
                        readOnly={true}
                        theme="bubble"
                        value={item.others}
                      />
                    </div>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
