import React, { useEffect, useState } from "react";
import styles from "./Styles/sections.module.scss";
// import { Values } from "../Data/testData";
import { getData } from "../utils/fetchingData";
export default function Education() {
  const [data, setData] = useState([]);
  const path = "EducationData/";
  useEffect(() => {
    getData(path, setData);
  }, []);

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h1>Education</h1>
      </section>
      {data.map((item, index) => {
        return (
          <div className={styles.row} key={index}>
            <div className={styles.column1}>
              <div className={styles.info}>
                <p>
                  <b>{item.year}</b>
                </p>
              </div>
            </div>
            <div className={styles.column2}>
              <div className={styles.info}>
                <p>
                  <b>{item.school}</b>
                  <br />
                  <i>{item.degree}</i>
                  <br />
                  {item.subject}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
