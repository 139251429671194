import React from "react";
import Carousel from "better-react-carousel";
import styles from "./Gallery.module.scss";
import ababil from "../../images/ababil.jpg";
import dinner from "../../images/dinner.jpg";
import im3 from "../../images/im3.jpg";
import coverImage from "../../images/coverImage.jpg";
export default function Gallery() {
  return (
    <>
      <div className={styles.Container}>
        <Carousel cols={1} rows={1} gap={10} loop autoplay={3000}>
          <Carousel.Item>
            <div class={styles.wrapper}>
              <img className={styles.image} src={ababil} alt="random 1" />
              <div class={styles.hovercap}>At BUET, Bangladesh</div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class={styles.wrapper}>
              <img className={styles.image} src={dinner} alt="random 1" />
              <div class={styles.hovercap}>
                Dinner with my Labmates at professors place in Davis,
                California, USA
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class={styles.wrapper}>
              <img className={styles.image} src={im3} alt="random 1" />
              <div class={styles.hovercap}>
                With notable professor, Constantine A. Balanis at 2019 IEEE APS
                conference in Atlanta, USA
              </div>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item>
            <div class={styles.wrapper}>
              <img className={styles.images} src={coverImage} alt="cover" />
              <div class={styles.hovercap}>
                At Golden gate bridge, San Francisco, USA
              </div>
            </div>
          </Carousel.Item> */}
        </Carousel>
      </div>
    </>
  );
}
