import React from "react";
import styles from "./Styles/social.module.scss";
import { SiGooglescholar, SiFacebook, SiLinkedin, SiResearchgate } from "react-icons/si";

export default function SocialIcons() {
  return (
    <div className={`${styles.social}`}>
      <a href="https://www.facebook.com/ababilh">
        <SiFacebook />
      </a>
      <a href="https://scholar.google.com/citations?user=T_VTwesAAAAJ&hl=en&oi=ao">
        <SiGooglescholar />
      </a>
      <a href="https://www.linkedin.com/in/ababil-hossain-ph-d-a9280a43/">
        <SiLinkedin />
      </a>
      <a href="https://www.researchgate.net/profile/M-Ababil-Hossain">
        <SiResearchgate />
      </a>
    </div>
  );
}
