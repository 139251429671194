import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import { useNavigate } from "react-router-dom";
import RoutingPaths from "../utils/RoutingPaths";

export default function CardComponent({item}) {
  let navigate = useNavigate();
  const handleClick = () => {
    if (item.id === 1) {
      navigate(RoutingPaths.ManageEducation);
    }else if(item.id===2){
      navigate(RoutingPaths.ManageHonorsAwards);
    }
    else if(item.id===3){
      navigate(RoutingPaths.ManageProfessionalExp);
    }
    else if(item.id===4){
      navigate(RoutingPaths.ManagePublications);
    }
  };
  return (
    <Card
      sx={{
        width: 200,
        height:200,
        backgroundColor: "#F5F8FA",
        textAlign: "center",
        margin:3,
        ":hover": {
          boxShadow: 5,
          backgroundColor: "#F5F8FA",
        },
      }}
      onClick={handleClick}
    >
      <CardActionArea>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <CardMedia
            sx={{ height: 100, width: 100 }}
            component="img"
            height="200"
            image={item.image}
            alt="green iguana"
          />
        </div>

        <CardContent>
          <Typography gutterBottom variant="body1" component="div">
            {item.type}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
