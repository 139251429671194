import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import sx from "./style";

export const PapersSection = ({ data, type }) => {
  return (
    <Box sx={sx.container}>
      <Typography variant="h5" sx={sx.typeTitle}>
        {type}
      </Typography>
      <Box sx={sx.paperContainer}>
        {data.map((item, index) => (
          <Box sx={sx.bookData} key={index}>
            <Typography sx={{ fontWeight: "bold" }}>
              {index + 1}. <Link to={item.link}>{item.title}</Link>
            </Typography>
            <Typography>{item.authors}</Typography>
            <Typography>{item.journal}</Typography>
          </Box>
        ))}
      </Box>

      <Divider />
    </Box>
  );
};
