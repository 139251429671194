import React from "react";
import { HonsAwardsData } from "../../../utils/constants";
import FormikFields from "../../../utils/FormikFields";

export default function ManageHonorsAwards() {
  const heading = "Add Honors & Awards Info";
  const initialValues = { year: "", title: "", school: "", others: "" };
  const path = HonsAwardsData
  const fields = ["year", "title", "school", "others"];
  return (
    <div>
      <FormikFields
        heading={heading}
        initialValues={initialValues}
        path={path}
        fields={fields}
      />
    </div>
  );
}
