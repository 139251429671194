import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PostData } from "./fetchingData";
import { TextField } from "formik-mui";
import { Button } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SelectFieldStyle = {
  padding: 7,
  fontSize: "0.75rem",
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ],
  // toolbar:false
};
export default function FormikFields({ path, initialValues, heading, fields, editorField }) {
  const [value, setValue] = useState("");
  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validate={(values) => {
        //   const errors = {};
        //   if (!values.school) {
        //     errors.school = "Required";
        //   }
        //   return errors;
        // }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (editorField) {
            values.others = value;
          }
          setTimeout(() => {
            PostData(path, values);
            setSubmitting(false);
            setValue("");
            resetForm();
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                gap: 20,
                marginTop: 20,
              }}
            >
              <h2>{heading}</h2>
              {fields.map((item) => {
                return (
                  <div>
                    <Field
                      type="text"
                      name={item}
                      placeholder={item}
                      component={TextField}
                      SelectProps={{
                        style: SelectFieldStyle,
                      }}
                      size="small"
                      fullWidth
                      margin="none"
                      variant="outlined"
                    />
                    <ErrorMessage name={item} component="div" />
                  </div>
                );
              })}
              {editorField && (
                <div className="container">
                  <ReactQuill
                    modules={modules}
                    readOnly={false}
                    theme="snow"
                    value={value}
                    placeholder="Details..."
                    onChange={setValue}
                  />
                </div>
              )}

              <Button variant="contained" type="submit" disabled={isSubmitting}>
                ADD
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
