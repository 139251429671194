import { Box, Typography } from "@mui/material";
import React from "react";
import coverImage from "../images/cover_photo.jpg";
import SocialIcons from "./SocialIcons";
import styles from "./Styles/about.module.scss";

export default function SelfInfo() {
  return (
    <div className={styles.Container}>
      <div className={styles.headerContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.textContainer}>
            <Box>
              <Typography variant="h5">Dr. Ababil Hossain</Typography>
              <Typography variant="body1">
                Ph.D. in EE, University of California, Davis, USA
              </Typography>
              <Typography variant="body1">
                RF/Antenna Engineer, Silicon Valley, California, USA
              </Typography>
            </Box>

            <div className={styles.rightInfo}>
              <p style={{ textAlign: "justify" }}>
                <img className={styles.image} src={coverImage} alt="Ababil Hossain" />I am an
                RF/Antenna expert working in a tech company in Silicon Valley, California. I
                completed my Ph.D. at the University of California-Davis. Previously, I received my
                M.S. degree in Electrical Engineering from Utah State University, USA, with an
                emphasis on reconfigurable antenna systems and radio frequency circuits. Originally,
                I am from Bangladesh and obtained both my B.Sc. and M.Sc. degrees in Electrical and
                Electronic Engineering from BUET, the most reputed and pioneering engineering
                institution in that country. During my Ph.D., I innovated novel electrically compact
                ultrawideband antennas for ground-penetrating radar imaging system applications. My
                current research interests are applied electromagnetics, antennas, and metasurfaces,
                primarily focusing on emerging wireless technologies, radar, and sensing systems.
              </p>
              <p>
                <b>Specialities:</b> Applied Electromagnetics, Antennas, Radar Systems,
                Metamaterials & 5G-mmWave.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.links}>
          <SocialIcons />
        </div>
      </div>
    </div>
  );
}
