import React from "react";
import styles from "./Styles/sections.module.scss";
export default function Contact() {
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <h4>
          Contact:{" "}
          <span style={{ fontSize: 16, fontWeight: "normal" }}>
            ababilh@gmail.com
          </span>
        </h4>
      </section>
    </div>
  );
}
