import React, { useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Education from "./Education";
import SelfInfo from "./SelfInfo";
import HonsAndAwards from "./HonsAwards";
import ProfessionalExperience from "./ProfessionalExperience";
import Publications from "./Publications";
import Contact from "./Contact";
import styles from "./Styles/navbar.module.scss";
import logo from "../images/Asset.svg";
import Gallery from "./Gallery/Gallery";
import RoutingPaths from '../utils/RoutingPaths'
import {useNavigate} from 'react-router-dom'
const pages = [
  "Home",
  "Education",
  "Honors & Awards",
  "Professional Experience",
  "Publications",
  "Gallery",
  "Contact",
  "Sign In"
];
const scrollToRef = (ref) =>
  window.scrollTo(0, ref.current ? ref.current.offsetTop : "");
export default function Navbar() {
  const navigate=useNavigate()
  const [gallery, setGallery] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const professionalexpRef = useRef(null);
  const aboutRef = useRef(null);
  const educationRef = useRef(null);
  const honsRef = useRef(null);
  const publicationsRef = useRef(null);
  const contactRef = useRef(null);
  const executeScroll = (page) => {
    switch (page) {
      case "Home":
        scrollToRef(aboutRef);
        setGallery(false);
        break;
      case "Education":
        setGallery(false);
        scrollToRef(educationRef);

        break;
      case "Honors & Awards":
        setGallery(false);
        scrollToRef(honsRef);

        break;
      case "Professional Experience":
        setGallery(false);
        scrollToRef(professionalexpRef);
        break;

      case "Publications":
        setGallery(false);
        scrollToRef(publicationsRef);
        break;

      case "Contact":
        setGallery(false);
        scrollToRef(contactRef);
        break;
      case "Gallery":
        // navigate(routes.Gallery)
        setGallery(true);
        break;
      case "Sign In":
        navigate(RoutingPaths.Login)
        break;
      default:
        scrollToRef(aboutRef);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar elevation={4} position="sticky" style={{ background: "#2e3b55" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img className={styles.icon} src={logo} alt="ababil hossain" />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              babil
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => executeScroll(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <img className={styles.icon2} src={logo} alt="ababil hossain" />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              babil
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => {
                return (
                  <Button
                    key={page}
                    onClick={() => executeScroll(page)}
                    className={styles.button}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      border: 1,
                      borderColor: "white",
                      marginRight: 1,
                      "&:hover": {
                        color: "black",
                        backgroundColor: "white",
                      },
                      "&:active": {
                        color: "black",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              })}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {gallery ? (
        <Gallery />
      ) : (
        <>
          <div ref={aboutRef}>
            <SelfInfo />
          </div>
          <div ref={educationRef}>
            <Education />
          </div>
          <div ref={honsRef}>
            <HonsAndAwards />
          </div>
          <div ref={professionalexpRef}>
            <ProfessionalExperience />
          </div>
          <div ref={publicationsRef}>
            <Publications />
          </div>
          <div ref={contactRef}>
            <Contact />
          </div>
        </>
      )}
    </>
  );
}
