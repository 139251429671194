const RoutingPaths={
    Home:'/',
    Login: "/login",
    Dashboard:'/dashboard',
    Gallery:'/gallery',
    ManageEducation:'/manageEducation',
    ManageHonorsAwards:'/manageHonorsAwards',
    ManagePublications:'/managePublications',
    ManageProfessionalExp:'/manageProfessionalExp'
}
export default RoutingPaths