const sx = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  paperContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "left",
  },
  bookData: {
    padding: "10px",
    width: "100%",
  },
  typeTitle: {
    borderBottom: "1px solid",
    width: "100%",
    display: "flex",
    // justifyContent: "center",
    padding: "10px",
  },
};
export default sx;
