import { getDatabase, ref, child, get,push } from "firebase/database";

export  const getData = (path,setter) => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, path))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let tempData = [];
          snapshot.forEach(function (item) {
            let itemVal = item.val();
            tempData.push(itemVal);
          });
          setter(tempData);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  export const PostData = (path,values) => {
    const database = getDatabase();
    const dbRef = ref(database);
    push(child(dbRef, path), values)
      .then(() => {
        alert("Data added");
      })
      .catch((error) => {
        console.log(error);
      });
  };