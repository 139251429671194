import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Experience from "../../images/profExpManage.png";
import education from "../../images/eduManage.png";
import publications from "../../images/publicationsManage.png";
import awards from "../../images/awardsManage.png";
import CardComponent from "../../utils/CardComponent";
import styles from "./Dashboard.module.scss";
import { Button } from "@mui/material";
import RoutingPaths from "../../utils/RoutingPaths";


const cardValues = [
  {
    id: 1,
    type: "Manage Education",
    image: education,
  },
  {
    id: 2,
    type: "Manage Honors&Awards",

    image: awards,
  },
  {
    id: 3,
    type: "Manage Professional Experience",

    image: Experience,
  },
  {
    id: 4,
    type: "Manage Publications",

    image: publications,
  },
];
export default function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user]);
  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className={styles.container}>
      <h1>Dashboard</h1>
      <div>
        {cardValues.map((item, index) => {
          return (
            <div key={index}>
              <CardComponent item={item} />
            </div>
          );
        })}
        
      </div>
      <div style={{marginTop:50}}>
      <Button variant="contained" sx={{backgroundColor:'red'}} onClick={handleLogout}>Sign out</Button>
      </div>
    </div>
  );
}
