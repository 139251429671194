import React from "react";
import { ProfessionalExpData } from "../../../utils/constants";
import FormikFields from "../../../utils/FormikFields";

export default function ManageProfessionalExp() {
  const heading = "Add Professional Experience Info";
  const initialValues = { year: "", title: "", organization: "", place: "",others:"" };
  const path = ProfessionalExpData
  const fields = ["year", "title", "organization","place"];
  const editorField=true;
  return (
    <div>
      <FormikFields
        heading={heading}
        initialValues={initialValues}
        path={path}
        fields={fields}
        editorField={editorField}
      />
    </div>
  );
}
