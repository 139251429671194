import React from "react";
import {
    BrowserRouter as Router,
    Navigate,
    Outlet,
    Route,
    Routes,
  } from "react-router-dom";
  import Login from "./Components/Login/Login";
  import Navbar from "./Components/Navbar";
  import Dashboard from "./Components/Dashboard/Dashboard";
  // import Gallery from "./Components/Gallery/Gallery";
  import {auth} from "./firebase";
  import { useAuthState } from "react-firebase-hooks/auth";
  import RoutingPaths from "./utils/RoutingPaths";
import ManageEducation from "./Components/Dashboard/ManageEducation/ManageEducation";
import ManageHonorsAwards from "./Components/Dashboard/ManageHonorsAwards/ManageHonorsAwards";
import ManageProfessionalExp from "./Components/Dashboard/ManageProfessionalExp/ManageProfessionalExp";
import ManagePublications from "./Components/Dashboard/ManagePublications/ManagePublications";
  

const PrivateWrapper = () => {
  const [user, loading, error] = useAuthState(auth);
  if (loading) {
    return <h1>Loading...</h1>;
  }
  return user ? <Outlet /> : <Navigate to={RoutingPaths.Login} />;
};

export default function PageRoutes() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Navbar />} />
          <Route path={RoutingPaths.Login} element={<Login />} />
          <Route path="*" element={<h1>No page found...</h1>} />
          {/* private routes */}
          <Route element={<PrivateWrapper />}>
            <Route path={RoutingPaths.Dashboard} element={<Dashboard />} />
            <Route path={RoutingPaths.ManageEducation} element={<ManageEducation />} />
            <Route path={RoutingPaths.ManageHonorsAwards} element={<ManageHonorsAwards />} />
            <Route path={RoutingPaths.ManageProfessionalExp} element={<ManageProfessionalExp />} />
            <Route path={RoutingPaths.ManagePublications} element={<ManagePublications />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}
