import React from "react";
import FormikFields from "../../../utils/FormikFields";

export default function ManageEducation() {
  const heading = "Add Education Info";
  const initialValues = { year: "", school: "", degree: "", subject: "" };
  const path = "EducationData/";
  const fields = ["year", "school", "degree", "subject"];
  return (
    <div>
      <FormikFields
        heading={heading}
        initialValues={initialValues}
        path={path}
        fields={fields}
      />
    </div>
  );
}
