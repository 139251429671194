import React from "react";
import { PublicationsData } from "../../../utils/constants";
import FormikFields from "../../../utils/FormikFields";

export default function ManagePublications() {
  const heading = "Add Publications Info";
  const initialValues = {
    year: "",
    title: "",
    authors: "",
    journal: "",
    link: "",
    type: "",
    position: 0,
  };
  const path = PublicationsData;
  const fields = ["year", "title", "authors", "journal", "link", "type", "position"];
  return (
    <FormikFields heading={heading} initialValues={initialValues} path={path} fields={fields} />
  );
}
